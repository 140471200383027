import { combineReducers } from "redux";
//import { persistor } from "../stores";
import FileSaver from "file-saver";

const loadingReducer = (loading = false, action) => {
  switch (action.type) {
    case "LOADING":
      return true;
    default:
      return false;
  }
};

const userLoginReducer = (
  user = {
    session: null,
    name: null,
    username: null,
    password: null,
    logout: null,
    loginfailed: null,
  },
  action
) => {
  if (action.type === "USER_LOGIN_SUCCEEDED") {
    return {
      session: action.payload.data.token,
      name: action.payload.data.name,
      username: null,
      password: null,
      logout: false,
      loginfailed: null,
    };
  }

  if (action.type === "USER_LOGIN_FAILED") {
    return {
      session: null,
      username: null,
      password: null,
      logout: null,
      loginfailed: true,
    };
  }

  if (action.type === "USER_LOGOUT") {
    return {
      session: null,
      username: null,
      password: null,
      logout: true,
      loginfailed: null,
    };
    // persistor.flush();
  }

  return user;
};
const shipmentPrintReducer = (print = null, action) => {
  if (action.type === "SHIPMENT_PRINTED") {
    const blob = new Blob([action.payload.data], {
      type: "application/pdf;base64",
    });
    const disposition = action.payload.headers["content-disposition"];
    if (disposition && disposition.indexOf("attachment") !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        const filename = matches[1].replace(/['"]/g, "");
        FileSaver.saveAs(blob, filename);
      }
    }
  }
  return print;
};

const shipmentsExportReducer = (shipments = { export: [] }, action) => {
  shipments = { ...shipments };
  if (action.type === "FETCH_SHIPMENTS_EXPORT_TOTAL") {
    shipments.export_totals = action.payload.data.totals.total_shipments;
    shipments.export_month = action.payload.month;
  }
  if (action.type === "FETCH_SHIPMENTS_EXPORT") {
    shipments.export = action.payload.data.shipments;
    shipments.export_month = action.payload.month;
    shipments.export_page = action.payload.page;
  }
  if (action.type === "RESET_SHIPMENTS_EXPORT") {
    shipments.export_page = undefined;
  }
  return shipments;
};

const shipmentsReducer = (shipments = { list: [] }, action) => {
  shipments = { ...shipments };
  if (action.type === "FETCH_SHIPMENTS") {
    shipments.list = action.payload.data.shipments;
    shipments.totals = action.payload.data.totals
      ? action.payload.data.totals
      : {
          total_shipments: 0,
          delivered: 0,
          postponed: 0,
          cancel: 0,
          others: 0,
        };
  }
  if (action.type === "UPDATE_ORDER") {
    shipments.list.filter((item) => item.id !== action.payload.data.id);
  }

  if (action.type === "FETCH_SHIPMENT") {
    shipments.fetchShipment = action.payload.data.shipments[0];
  }

  if (action.type === "SHIPMENT_IMPORT_SUCCEEDED") {
    shipments.importResult = { status: true, result: action.payload };
  }
  if (action.type === "SHIPMENT_IMPORT_FAILED") {
    shipments.importResult = { status: false, result: action.payload };
  }

  return shipments;
};

const ashipmentReducer = (shipment = {}, action) => {
  if (action.type === "SHIPMENT_CREATED") {
    shipment = {};
    shipment = { status: true, result: action.payload };
  }
  if (action.type === "SHIPMENT_FAILED") {
    shipment = {};
    shipment = { status: false, result: action.payload };
  }

  return shipment;
};

const citiesReducer = (cities = [], action) => {
  if (action.type === "FETCH_CITIES") {
    return action.payload.data.subzones;
  }

  return cities;
};

const statesReducer = (states = [], action) => {
  if (action.type === "FETCH_STATES") {
    return action.payload.data.states;
  }

  return states;
};

const dashboardReducer = (data = [], action) => {
  if (action.type === "FETCH_DASHBOARD") {
    return action.payload.data;
  }

  return data;
};

const shipmentFlashReducer = (created = false, action) => {
  switch (action.type) {
    case "SHIPMENT_CREATED":
    case "SHIPMENT_IMPORT_SUCCEEDED":
      return true;
    default:
      return false;
  }
};

const sideBarShowReducer = (sidebarShow = "responsive", action) => {
  switch (action.type) {
    case "SIDEBAR_STATUS":
      return action.sidebarShow;
    default:
      return sidebarShow;
  }
};

export default combineReducers({
  shipments: shipmentsReducer,
  cities: citiesReducer,
  states: statesReducer,
  sidebarShow: sideBarShowReducer,
  // shipmentsDashboard: shipmentsDashboardReducer,
  // pendingShipments: shipmentsPendingReducer,
  // bulkShipment: bulkShipmentReducer,
  // postponeReasons: postponeReasonsReducer,
  // cancelReasons: cancelReasonsReducer,
  loading: loadingReducer,
  shipmentPrint: shipmentPrintReducer,
  shipment: ashipmentReducer,
  shipmentFlash: shipmentFlashReducer,
  // language: languageReducer,
  // reloadShipment: reloadShipmentReucer,
  userSession: userLoginReducer,
  dashboard: dashboardReducer,
  shipmentsExport: shipmentsExportReducer,
});
